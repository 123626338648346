
var jsmenu = document.querySelector('.js-menu');
var body = document.querySelector('body');
var menuToggle = document.querySelector('.js-menu-toggle');

menuToggle.addEventListener('click', function(e){
	e.preventDefault();
	jsmenu.classList.toggle('is-open');
	body.classList.toggle('is-open');
});

var anchor_links = document.querySelectorAll('a[href^="#"]');

for (var i = 0; i < anchor_links.length; i++) {

	anchor_links[i].addEventListener('click', function(e) {

		e.preventDefault();

		var href = this.getAttribute('href');
		if (href == '#') return;

		var target = document.querySelector(href);
		if (!target) return;

		scrollTo(document.body, (offset(target).top - 50), 600);

		jsmenu.classList.remove('is-open');

	});

}


function getOffset(el) {
  el = el.getBoundingClientRect();
  return {
    left: el.left + window.scrollX,
    top: el.top + window.scrollY
  };
}

function parallax() {

	var scroll = window.scrollY + window.outerHeight;

	var elements = document.getElementsByClassName('js-parallax');

	for (var i = 0; i < elements.length; i++) {

		var element = elements[i];

		var offset = getOffset(element).top;

		if((offset <= scroll) && ((offset + element.clientHeight) >= window.scrollY)) {

			var percentScrolled = window.outerHeight + element.clientHeight;

			percentScrolled = (100 / percentScrolled) * (scroll - offset);

			var percentScrolledScale = (percentScrolled / 100);

			var tollerence = 30;
			var activeMovement = (percentScrolledScale * (tollerence * 2)) - tollerence;

			var elementImage = element.children[0];

			elementImage.style.transform = "translate3d(0, "+activeMovement+"%, 0)";

		}

	}


}

window.requestAnimationFrame(parallax);

window.onscroll = function (e) {
	window.requestAnimationFrame(parallax);
	myFunction();
};


//embedded youtube play button

        jQuery(document).ready(function() {
          jQuery('.icon-play-ytb').on('click', function() {
           // get url of iframe
           var iframe_obj = jQuery(this).closest('.video-wrap').find('iframe');
           var url = iframe_obj.attr('src');
           jQuery(this).closest('.slide__image').delay(300).fadeOut(300, function() { $(this).remove(); });
           iframe_obj.attr('src', url + "&autoplay=1");

					 jQuery(this).closest('.embed-responsive-16by9').addClass('active');
       });
   });

//isotope

	 jQuery(window).load(function() {

	           var main = jQuery('.grid');
	           main.isotope({
	             itemSelector: '.grid-item',
	             percentPosition: true,
	             layoutMode: 'packery',
	             packery: {
	               columnWidth: '.grid-sizer',
	               gutter: '.gutter-sizer'
	             },
	           });

						 var mainEl = jQuery('.project-grid');
							mainEl.isotope({
								itemSelector: '.grid-item',
								layoutMode: 'packery',
								packery: {
									columnWidth: '.grid-sizer',
									gutter: '.gutter-sizer'
								},
  							sortAscending: false,
							});



						 // filter functions
									var filterFns = {

									};
									// bind filter button click
									jQuery('.filters-button-group').on('click', 'a', function() {
										var filterValue = jQuery(this).attr('data-filter');
										// use filterFn if matches value
										filterValue = filterFns[filterValue] || filterValue;
										main.isotope({
											filter: filterValue
										});
									});

									// bind sort button click
									jQuery('.sort-by-button-group').on('click', 'a', function() {
										var sortValue = jQuery(this).attr('data-sort-value');
										main.isotope({
											sortBy: sortValue
										});
									});

									// change is-checked class on buttons
								jQuery('.button-group').each(function(i, buttonGroup) {
										var $buttonGroup = jQuery(buttonGroup);
										var $fadeonactive = jQuery('.fadeOnActive');
										$buttonGroup.on('click', 'a', function() {
											$fadeonactive.removeClass('active');
											$buttonGroup.find('.active').removeClass('active');
											jQuery(this).addClass('active');
										});
									});

									jQuery(document).ready(function() {


									mainEl.isotope('shuffle');
									});

			 				});





// sliders

			jQuery('.ctaslider').slick({
				dots: false,
				arrows: false,
				infinite: true,
				autoplay: true,
				autoplaySpeed: 4000,
				speed: 200,
				fade: false,
				cssEase: 'linear'
			});

			jQuery('.featuredslider').slick({
				dots: false,
				infinite: true,
				autoplay: true,
				autoplaySpeed: 12000,
				speed: 500,
				fade: true,
				cssEase: 'linear'
			});

			//slickslider with multiple images per page
			jQuery('.multiple-items').slick({
				infinite: true,
				slidesToShow: 5,
				autoplay: true,
				autoplaySpeed: 6000,
				centerMode: true,
				variableWidth: true,
				responsive: [
							{
								breakpoint: 1200,
								settings: {
								slidesToShow: 5,
								slidesToScroll: 2,
								}
							},

							{
								breakpoint: 768,
								settings: {
									slidesToShow: 3,
									slidesToScroll: 2,
								}
							},
							{
								breakpoint: 576,
								settings: {
									centerMode: true,
									centerPadding: '40px',
									slidesToShow: 1,
								}
							}
						]
				});


				//slickslider with multiple images per page
			            jQuery('.content-block').slick({
			              infinite: true,
			              slidesToShow: 4,
			              autoplay: true,
			              autoplaySpeed: 6000,
			              variableWidth: false,
			              responsive: [
			                    {
			                      breakpoint: 1200,
			                      settings: {
			                      slidesToShow: 4,
			                      slidesToScroll: 2,
			                      }
			                    },

			                    {
			                      breakpoint: 768,
			                      settings: {
			                        slidesToShow: 2,
			                        slidesToScroll: 2,
			                      }
			                    },
			                    {
			                      breakpoint: 576,
			                      settings: {
			                        centerMode: true,
			                        centerPadding: '40px',
			                        slidesToShow: 1,
			                      }
			                    }
			                  ]

			              });

				jQuery(function () {
		                jQuery('[data-toggle="tooltip"]').tooltip();
		              });

// Image popups

        jQuery('.open-popup').magnificPopup({
          type: 'image',

        //  delegate: '[rel="lightbox"]',
          fixedContentPos: false,
          gallery: {
            enabled: true
          },
          removalDelay: 500, //delay removal by X to allow out-animation
          callbacks: {
            beforeOpen: function() {
              // just a hack that adds mfp-anim class to markup
              this.st.image.markup = this.st.image.markup.replace('mfp-figure', 'mfp-figure mfp-with-anim');
              this.st.mainClass = this.st.el.attr('data-effect');
            },
            open: function() {
              jQuery('body').addClass('noscroll');
            },
            close: function() {
              jQuery('body').removeClass('noscroll');
            },
          },
          closeOnContentClick: true,
          midClick: true // allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source.
        });

// Bio popups

							jQuery('.open-modal').magnificPopup({
              type:'inline',
              midClick: true,
              gallery:{
                enabled:false
              },
              removalDelay: 500, //delay removal by X to allow out-animation
              callbacks: {
                  beforeOpen: function() {
                     this.st.mainClass = this.st.el.attr('data-effect');
                  },
                open: function() {
                  jQuery('body').addClass('noscroll');
                },
                change: function() {
                    if (this.isOpen) {
                        this.wrap.addClass('mfp-open');
                    }
                },
                close: function() {
                  jQuery('body').removeClass('noscroll');
                },
              },
              closeOnContentClick: false,

            });


						// Animate on Scroll

                jQuery(window).load(function() {
                  AOS.init({
                    duration: 900,
                  });
                });

                jQuery(window).on('resize', function(){
                    AOS.refresh();
                });

// sticky menu




// Get the header
var header = document.getElementById("sticky-header");
var menu = document.getElementById("sticky-menu");


var stickyheader = header.offsetTop;
var stickymenu = menu.offsetTop;


function myFunction() {

	var cList = document.body.classList;
	if (
	  cList.contains('home') )  {


		if (window.pageYOffset > (stickyheader + (window.innerHeight * 1) )) {
			header.classList.add("sticky");
		} else {
			header.classList.remove("sticky");
		}

	  } else if (  cList.contains('archive'))  {

		if (window.pageYOffset > (stickyheader + (window.innerHeight * 0.5) )) {
			header.classList.add("sticky");
		} else {
			header.classList.remove("sticky");
		}

	} else {
		if (window.pageYOffset > (stickyheader + (window.innerHeight * 0.2) )) {
			header.classList.add("sticky");
		} else {
			header.classList.remove("sticky");
		}
	}

  if (window.pageYOffset > (stickymenu - 50) )  {
    menu.classList.add("sticky");
  } else {
    menu.classList.remove("sticky");
  }
}

